import { Spin } from "antd";
import { useSelector } from "react-redux";
import logo from "../../assets/logo/rune-icp-grey-color.png";

const LoadingWrapper = ({ children }) => {
  const loading = useSelector((state) => state.constant.isLoading);
  return (
    <Spin
      style={{ zIndex: 2 }}
      indicator={<img className="image" src={logo} alt="loader"></img>}
      spinning={loading}
    >
      {children}
    </Spin>
  );
};

export default LoadingWrapper;
