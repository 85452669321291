import { Col, Flex, Input, Row, Skeleton, Typography } from "antd";
import { useEffect, useState } from "react";
import { IoMdDoneAll } from "react-icons/io";
import { PiSmileyWinkBold } from "react-icons/pi";
import CustomButton from "../../component/button";
import CardDisplay from "../../component/card";
import Notify from "../../component/notification";
import PropsContainer from "../../container/props-container";
// import { setIsLoading } from "../../redux/slice";
import { Principal } from "@dfinity/principal";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import ModalDisplay from "../../component/modal";
import { mintIdlFactory } from "../../mint_canister";
import { setIsLoading } from "../../redux/slice/constant";
import {
  agentCreator,
  mintCanisterId,
  nftAgent,
  nftCanisterId,
  tokenIdentifier,
} from "../../utils/common";
import { nftIdlFactory } from "../../nft_canister";

const Nft = (props) => {
  const { activeKey } = props;
  const { reduxState, dispatch } = props.redux;
  const { Text } = Typography;
  const [recipientAddress, setRecipientAddress] = useState("");
  const [transferModal, setTransferModal] = useState(false);
  const [token, setToken] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const accountId = reduxState.wallet.accountId;
  const plugAddress = reduxState.wallet.principalId;

  const [screenDimensions, setScreenDimensions] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });
  const getScreenDimensions = (e) => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setScreenDimensions({ width, height });
  };

  useEffect(() => {
    window.addEventListener("resize", getScreenDimensions);
    return () => {
      window.removeEventListener("resize", getScreenDimensions);
    };
  });

  const [nftTokens, setNftTokens] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [nftActivatedTokens, setNftActivatedTokens] = useState([]);

  const fetchTokenItems = async () => {
    const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
    const nft_API = agentCreator(nftIdlFactory, nftCanisterId);
    // Fetching user tokens
    // const tokens = await nft_API.tokens(
    //   "b5f06fc926e69b70ed85ee30e4ded641914584ebe702af6626c8505307894d49"
    // );
    const tokens = await nft_API.tokens(accountId);

    if (tokens?.err) {
      setNftTokens([]);
      return;
    }
    if (tokens.ok.length) {
      const resultData = [];
      tokens.ok.forEach((data) => {
        const tokenId = tokenIdentifier(nftCanisterId, data);
        resultData.push(tokenId);
      });
      setNftTokens(resultData);

      if (resultData.length < 12) {
        // Inspecting activated tokens
        const promises = resultData.map((tokenId) => {
          return new Promise(async (Res) => {
            const response = await mint_API.getActivatedTime(tokenId);
            Res(response ? tokenId : 0);
          });
        });
        const revealedPromise = await Promise.all(promises);
        const activatedTokens = revealedPromise.filter((tokenId) => tokenId);

        setNftActivatedTokens(activatedTokens);
      }
    } else {
      setNftTokens([]);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (plugAddress && activeKey === "2") {
          await fetchTokenItems();
        } else {
          setNftTokens(["", "", "", "", "", "", "", "", "", "", "", ""]);
        }
      } catch (error) {
        console.log("Token fetching error", error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plugAddress, activeKey]);

  const handleActivation = async (token) => {
    try {
      dispatch(setIsLoading(true));
      const mint_API = agentCreator(mintIdlFactory, mintCanisterId);

      const activationResult = await mint_API.activateRSIC(token);
      const response = await mint_API.getActivatedTime(token);

      if (response) {
        const array = [...nftActivatedTokens, token];
        setNftActivatedTokens(array);
      }

      if (activationResult) {
        Notify("success", "Mint Activated Successfully !");
      } else {
        Notify("error", "Mint already activated !");
      }

      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.log("error", error.message);
    }
  };

  const handleCancel = () => {
    setTransferModal(false);
  };

  const handleTransfer = async () => {
    try {
      setButtonLoading(true);
      const nft_API = agentCreator(nftIdlFactory, nftCanisterId);

      const transferArguments = {
        to: {
          address: recipientAddress,
        },
        token: token,
        notify: false,
        from: {
          address: plugAddress,
        },
        memo: [],
        subaccount: [],
        amount: 1n,
      };

      if (Principal.fromText(recipientAddress)._isPrincipal) {
        const transferResult = await nft_API.transfer(transferArguments);
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
      if (error.message.includes("may not be a valid Principal ID")) {
        Notify("error", "Please input valid address");
      }
    }
  };

  return (
    <>
      <Row justify={"start"} className="mt-30">
        <Text className="color-white font-size-20 font-family-one">
          {nftTokens[0] === "" ? 0 : nftTokens.length} RSIC{" "}
          {nftTokens.length === 1 ? "miner" : "miners"} - found
        </Text>
      </Row>
      <Row justify={"center"} align={"middle"} className="mt-25 mb-25">
        <Col xs={24}>
          {nftTokens.length !== 0 ? (
            <Row gutter={[45, 28]}>
              {nftTokens.map((token, index) => {
                const isActivated = nftActivatedTokens.includes(token);

                return (
                  <Col key={`token-${index}`} md={!nftTokens[0] && 6}>
                    <Skeleton loading={!nftTokens[0]} active>
                      <CardDisplay
                        bordered={false}
                        style={{
                          backgroundColor: "#78A083",
                          position: "relative",
                          zIndex: 1,
                        }}
                        className={"border-radius-25 float-up-medium"}
                      >
                        <div class="card">
                          <div class="card-details">
                            <p class="text-body">
                              <iframe
                                title="cards"
                                src={`https://efbpx-hqaaa-aaaam-ab7ja-cai.raw.icp0.io/?tokenid=${token}`}
                                alt="noimg"
                                className="border-radius-25"
                                width={screenDimensions.width > 390 ? 300 : 240}
                                height={
                                  screenDimensions.width > 390 ? 300 : 240
                                }
                              />
                            </p>
                          </div>

                          {!isActivated ? (
                            <Row
                              justify={"space-between"}
                              className="mt-30"
                              gutter={16}
                            >
                              <Col xs={24}>
                                <CustomButton
                                  style={{ width: "100%" }}
                                  title={
                                    <Text className="animation">
                                      <span
                                      // style={{
                                      //   position: "absolute",
                                      //   left: 23,
                                      //   top: 10,
                                      // }}
                                      >
                                        Activate
                                      </span>
                                    </Text>
                                  }
                                  className={
                                    "activate-button button-style scale-activate-button btn font-weight-600 card-button"
                                  }
                                  onClick={() =>
                                    !isActivated && handleActivation(token)
                                  }
                                />
                              </Col>

                              {/* <Col xs={11}>
                                <CustomButton
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#c95e5e",
                                  }}
                                  title={"Transfer"}
                                  className={
                                    "activate-button button-style scale-activate-button btn font-weight-600 card-button"
                                  }
                                  onClick={() => {
                                    setTransferModal(true);
                                    setToken(token);
                                  }}
                                />
                              </Col> */}
                            </Row>
                          ) : (
                            <></>
                            // <CustomButton
                            //   style={{
                            //     width: "100%",
                            //     backgroundColor: "#c95e5e",
                            //   }}
                            //   title={"Transfer"}
                            //   className={
                            //     "activate-button button-style scale-activate-button btn font-weight-600 card-button"
                            //   }
                            //   onClick={() => {
                            //     setTransferModal(true);
                            //     setToken(token);
                            //   }}
                            // />
                          )}
                        </div>
                        {isActivated && (
                          <IoMdDoneAll
                            color="green"
                            size={35}
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "-15px",
                              backgroundColor: "lightgreen",
                              padding: "3px",
                              boxShadow: "0px 1px 27px rgb(5 255 105 / 65%)",
                            }}
                            className="border-radius-25 "
                          />
                        )}
                      </CardDisplay>
                    </Skeleton>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Row justify={"center"} className="mt-10">
              <Text className="color-violet-one font-size-20 font-weight-600 icon-alignment gap-5">
                <PiSmileyWinkBold size={25} /> No minted tokens to see !
              </Text>
            </Row>
          )}
        </Col>
      </Row>
      {/* <Row className="snow"></Row> */}
      <ModalDisplay
        width={"25%"}
        open={transferModal}
        onCancel={handleCancel}
        footer={null}
        title={
          <Row className="black-bg color-white font-size-23 font-family-one">
            Transfer RSIC miner
          </Row>
        }
      >
        <Flex vertical gap={5}>
          <Text className="text-color-two font-size-18 font-family-two">
            To (Recipient address)
          </Text>
          <Input
            className="inputStyle"
            size="large"
            value={recipientAddress}
            onChange={(e) => setRecipientAddress(e.target.value)}
          />
        </Flex>

        <Row className="modalBoxYellowShadow mt-10">
          <Col className="icon-alignment gap-5">
            <HiOutlineInformationCircle size={20} className="mb-20" />
            You are transferring this miner to above user address.
          </Col>
        </Row>
        <Row>
          <CustomButton
            disabled={buttonLoading ? true : false}
            className={"font-weight-600 mt-15 width-100 button transfer-button"}
            title={!buttonLoading ? "Transfer miner" : <div class="ring"></div>}
            onClick={!buttonLoading ? handleTransfer : () => {}}
          />
        </Row>
      </ModalDisplay>
    </>
  );
};

export default PropsContainer(Nft);
