import { Principal } from "@dfinity/principal";
import { Col, Flex, Input, Row, Skeleton, Tooltip, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaParachuteBox, FaWallet } from "react-icons/fa6";
import { GiProgression } from "react-icons/gi";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { LuBaggageClaim } from "react-icons/lu";
import { MdRocketLaunch } from "react-icons/md";
import { PiSmileyWinkBold } from "react-icons/pi";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import ThreeDots from "react-loading-icons/dist/esm/components/three-dots";
import Bitcoin from "../../assets/ckbtc.png";
import { boosterIdlFactory } from "../../booster_canister";
import { ckBtcIdlFactory } from "../../ckBTC_canister";
import CustomButton from "../../component/button";
import CardDisplay from "../../component/card";
import Loading from "../../component/loading-wrapper/secondary-loader";
import ModalDisplay from "../../component/modal";
import Notify from "../../component/notification";
import PropsContainer from "../../container/props-container";
import { mintIdlFactory } from "../../mint_canister";
import { setIsLoading } from "../../redux/slice";
import { tokenIdlFactory } from "../../token_canister";
import {
  actorAgentCreator,
  agentCreator,
  boosterCanisterId,
  btcCanisterId,
  mintCanisterId,
  tokenCanisterId,
  tokenIdentifier,
} from "../../utils/common";

const Runes = (props) => {
  /* global BigInt */
  const { Text } = Typography;
  const { activeKey } = props;
  const { reduxState, dispatch } = props.redux;
  const accountId = reduxState.wallet.accountId;
  const plugAddress = reduxState.wallet.principalId;
  const ckBtcValue = reduxState.wallet.ckBtcValue;

  const BTC_ZERO = process.env.REACT_APP_BTC_ZERO;
  const foundaryID = process.env.REACT_APP_FOUNDRY_ID;

  const [fee, setFee] = useState(null);
  // const [blocks, setBlocks] = useState(0);
  const [claimedProgress, setclaimedProgress] = useState(null);
  const [runeBalance, setRuneBalance] = useState(null);
  const [walletRuneBalance, setWalletRuneBalance] = useState(null);
  const [claimableRunes, setClaimableRunes] = useState(null);
  const [isClaimModal, setIsClaimModal] = useState(false);
  const [boosterTokens, setBoosterTokens] = useState(null);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });

  const getScreenDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setScreenDimensions({ width, height });
  };

  const handleClaimModal = () => {
    setIsClaimModal(true);
  };

  const handleCancel = () => {
    setIsClaimModal(false);
  };

  const fetchClaimedRunes = async () => {
    try {
      const token_API = agentCreator(tokenIdlFactory, tokenCanisterId);
      const claimedRunes = await token_API.icrc1_balance_of({
        owner: Principal.fromText(plugAddress),
        subaccount: [],
      });
      if (claimedRunes) {
        setWalletRuneBalance(Number(claimedRunes) / 100);
      } else {
        setWalletRuneBalance(0);
      }
    } catch (error) {
      console.log("claim runes error", error);
    }
  };

  const fetchRuneBalance = async () => {
    try {
      const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
      const { data: blocks } = await axios.get(
        process.env.REACT_APP_MEMPOOL_BLOCK_HEIGHTS
      );

      await mint_API.getRunes(accountId, blocks);
      const result = await mint_API.getRuneBalance(accountId);
      if (result) {
        setRuneBalance(Number(result));
      } else {
        setRuneBalance(0);
      }
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handleClaimRunes = async (mint_API) => {
    try {
      const claimArgs = {
        to: {
          owner: Principal.fromText(plugAddress),
          subaccount: [],
        },
        fee: [],
        memo: [],
        from_subaccount: [],
        created_at_time: [],
        amount: BigInt(Math.round(claimableRunes * 100)),
      };
      const claimRes = await mint_API.mintRuneTokens(
        claimArgs,
        Number(foundaryID)
      );
      return claimRes;
    } catch (error) {
      console.log("Claim error", error);
      return { Err: false };
    }
  };

  const handleCkBtcTransfer = async () => {
    const ckBtcAgent = actorAgentCreator(ckBtcIdlFactory, btcCanisterId);
    const mint_API = agentCreator(mintIdlFactory, mintCanisterId);

    const transferArgs = {
      to: {
        owner: Principal.fromText(tokenCanisterId),
        subaccount: [],
      },
      fee: [],
      memo: [],
      created_at_time: [],
      from_subaccount: [],
      amount: BigInt(fee),
    };
    try {
      setIsTransferLoading(true);
      const { Err } = await ckBtcAgent.icrc1_transfer(transferArgs);

      if (Err) {
        Notify("error", "An error occurred in tranferring ckBTC !");
      } else {
        if (claimableRunes * 100 >= 100) {
          const { Ok } = await handleClaimRunes(mint_API);
          handleCancel();

          if (Ok) {
            Notify("success", "The runes have been successfully claimed!");
            await fetchRuneBalance();
            fetchClaimedRunes();
          } else {
            Notify("error", "An error occurred in claiming runes !");
          }
        } else {
          Notify("warning", "You must have at least one rune to make a claim.");
        }
      }
    } catch (error) {
      setIsTransferLoading(false);
      console.log("ckBtc transfer error", error);
    }
  };

  const handleClaims = async () => {
    const mint_API = agentCreator(mintIdlFactory, mintCanisterId);

    if (true) {
      handleClaimModal();
    } else if (runeBalance) {
      dispatch(setIsLoading(true));
      if (claimableRunes * 100 >= 100) {
        const { Ok } = await handleClaimRunes(mint_API);
        if (Ok) {
          Notify("success", "The runes have been successfully claimed!");
          await fetchRuneBalance();
          fetchClaimedRunes();
        } else {
          Notify("error", "An error occurred in claiming runes !");
        }
        dispatch(setIsLoading(false));
      } else {
        Notify("warning", "You must have at least one rune to make a claim.");
      }
    }
  };

  const fetchFee = async () => {
    const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
    const fee = await mint_API.claimFee();
    setFee(fee);
  };

  useEffect(() => {
    if (runeBalance !== null && walletRuneBalance !== null) {
      let mintAmount;
      if (runeBalance > walletRuneBalance) {
        mintAmount = runeBalance - walletRuneBalance;
      } else {
        mintAmount = walletRuneBalance - runeBalance;
      }
      setClaimableRunes(mintAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runeBalance, walletRuneBalance]);

  useEffect(() => {
    fetchFee();
  }, []);

  useEffect(() => {
    fetchClaimedRunes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getScreenDimensions);
    return () => {
      window.removeEventListener("resize", getScreenDimensions);
    };
  });

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await axios.get(
  //       "https://mempool.space/api/blocks/tip/height"
  //     );
  //     setBlocks(data);
  //   })();
  // }, []);

  const fetchBoostedSupply = async () => {
    const booster_API = agentCreator(boosterIdlFactory, boosterCanisterId);
    const { ok, err } = await booster_API.tokens(accountId);

    const resultData = [];
    ok.forEach((data) => {
      const tokenId = tokenIdentifier(boosterCanisterId, data);
      resultData.push(tokenId);
    });

    if (err) {
      setBoosterTokens([]);
    } else {
      setBoosterTokens(resultData);
    }
  };

  const fetchClaimProgress = async () => {
    const token_API = agentCreator(tokenIdlFactory, tokenCanisterId);
    const claimed = await token_API.icrc1_claimed();
    const totalSupply = await token_API.icrc1_total_supply();
    const claimedProgress = (Number(claimed) / Number(totalSupply)) * 100;
    setclaimedProgress(claimedProgress);
  };

  useEffect(() => {
    (async () => {
      fetchClaimProgress();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, activeKey]);

  useEffect(() => {
    (async () => {
      fetchRuneBalance();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, activeKey]);

  useEffect(() => {
    (async () => {
      await fetchBoostedSupply();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <Row justify={"center"}>
      <Col md={24}>
        <Row className="mt-10" justify={"start"} gutter={80}>
          <Col>
            <Flex vertical gap={10} justify="center">
              <Text className="font-size-20 font-weight-600 color-white">
                Total Runes Earned
              </Text>
              <CardDisplay className={"card-bg"}>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="gap-5 icon-alignment"
                >
                  <Col>
                    <Text className="font-size-18">Runes</Text>
                    <Row>
                      {runeBalance !== null || walletRuneBalance !== null ? (
                        <Text className="font-size-18 font-weight-600">
                          {(runeBalance + walletRuneBalance).toLocaleString()}
                        </Text>
                      ) : (
                        <Loading
                          indicator={
                            <ThreeDots
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!runeBalance}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Text>
                      {boosterTokens === null ? (
                        <Loading
                          indicator={
                            <TailSpin
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!runeBalance}
                        />
                      ) : boosterTokens.length ? (
                        <Tooltip
                          title="Mining Boosted"
                          trigger={"hover"}
                          color="purple"
                        >
                          <MdRocketLaunch
                            color="red"
                            className="launchIconStyle scale-animation mt-5 pointer"
                            size={30}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Text>
                  </Col>
                </Row>
              </CardDisplay>
            </Flex>
          </Col>

          <Col>
            <Flex vertical gap={10} justify="center">
              <Text className="font-size-20 font-weight-600 color-white">
                Claim Balance
              </Text>
              <CardDisplay className={"card-bg"}>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="gap-5 icon-alignment"
                >
                  <Col>
                    <Text className="font-size-18">Runes</Text>
                    <Row>
                      {runeBalance !== null ? (
                        <Text className="font-size-18 font-weight-600">
                          {runeBalance.toLocaleString()}
                        </Text>
                      ) : (
                        <Loading
                          indicator={
                            <ThreeDots
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!runeBalance}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Text>
                      {boosterTokens === null ? (
                        <Loading
                          indicator={
                            <TailSpin
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!runeBalance}
                        />
                      ) : boosterTokens.length ? (
                        <Tooltip
                          title="Claimable"
                          trigger={"hover"}
                          color="purple"
                        >
                          <FaParachuteBox
                            color="green"
                            className="launchIconStyle-one scale-animation mt-5 pointer"
                            size={30}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Text>
                  </Col>
                </Row>
              </CardDisplay>

              <CustomButton
                loading={!fee || claimableRunes === null}
                className={"font-weight-600 mt-15 width-100 button-style"}
                title={"Claim runes"}
                onClick={handleClaims}
              />
            </Flex>
          </Col>
          <Col>
            <Flex vertical gap={10}>
              <Text className="font-size-20 font-weight-600 color-white">
                Wallet Balance
              </Text>
              <CardDisplay style={{ width: "200px" }} className={"card-bg"}>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="gap-5 icon-alignment"
                >
                  <Col>
                    <Text className="font-size-18">Runes</Text>
                    <Row>
                      {walletRuneBalance !== null ? (
                        <Text className="font-size-18 font-weight-600">
                          {walletRuneBalance.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </Text>
                      ) : (
                        <Loading
                          indicator={
                            <ThreeDots
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!walletRuneBalance}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Text>
                      <Tooltip title="Wallet" trigger={"hover"} color="purple">
                        <FaWallet
                          color="green"
                          className="launchIconStyle-one scale-animation mt-5 pointer"
                          size={30}
                        />
                      </Tooltip>
                    </Text>
                  </Col>
                </Row>
              </CardDisplay>
            </Flex>
          </Col>
          <Col>
            <Flex vertical gap={10}>
              <Text className="font-size-20 font-weight-600 color-white">
                Claim Progress
              </Text>
              <CardDisplay style={{ width: "200px" }} className={"card-bg"}>
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  className="gap-5 icon-alignment"
                >
                  <Col>
                    <Text className="font-size-18">Out of 100</Text>
                    <Row>
                      {claimedProgress !== null ? (
                        <Text className="font-size-18 font-weight-600">
                          {claimedProgress.toFixed(2)}%
                        </Text>
                      ) : (
                        <Loading
                          indicator={
                            <ThreeDots
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!claimedProgress}
                        />
                      )}
                    </Row>
                  </Col>

                  <Col>
                    <Text>
                      <Tooltip
                        title="Progress"
                        trigger={"hover"}
                        color="purple"
                      >
                        <GiProgression
                          color="green"
                          className="launchIconStyle-one scale-animation mt-5 pointer"
                          size={30}
                        />
                      </Tooltip>
                    </Text>
                  </Col>
                </Row>
              </CardDisplay>
            </Flex>
          </Col>
        </Row>

        <Row justify={"start"} className="mt-30">
          <Text className="color-white font-size-20 font-family-one">
            {boosterTokens === null ? 0 : boosterTokens.length} Booster{" "}
            {boosterTokens === null
              ? "token"
              : boosterTokens.length < 0
              ? "token"
              : "tokens"}{" "}
            - found
          </Text>
        </Row>

        <Row justify={"center"} align={"middle"} className="mt-25 mb-25">
          <Col xs={24}>
            {boosterTokens === null ? (
              <Loading
                indicator={
                  <TailSpin
                    stroke="#a031fa"
                    strokeWidth={"5"}
                    fill="#b458ff"
                    alignmentBaseline="central"
                  />
                }
                spin={!runeBalance}
              />
            ) : boosterTokens.length ? (
              <Row gutter={[45, 28]}>
                {boosterTokens.map((token, index) => {
                  return (
                    <Col
                      key={`token-${index}`}
                      md={boosterTokens.length === 0 && 6}
                    >
                      <Skeleton loading={boosterTokens.length === 0} active>
                        <CardDisplay
                          bordered={false}
                          style={{
                            backgroundColor: "#78A083",
                            position: "relative",
                            zIndex: 1,
                            padding: "0px !important",
                          }}
                          className={"border-radius-25 float-up-medium"}
                        >
                          <div className="card">
                            <div className="card-details">
                              <p className="text-body">
                                <iframe
                                  title="cards"
                                  src={`https://${boosterCanisterId}.raw.icp0.io/?tokenid=${token}`}
                                  alt="noimg"
                                  className="border-radius-25"
                                  width={
                                    screenDimensions.width > 390 ? 300 : 240
                                  }
                                  height={
                                    screenDimensions.width > 390 ? 300 : 240
                                  }
                                />
                              </p>
                            </div>
                          </div>
                        </CardDisplay>
                      </Skeleton>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <Row justify={"center"} className="mt-10">
                <Text className="color-violet-one font-size-20 font-weight-600 icon-alignment gap-5">
                  <PiSmileyWinkBold size={25} /> No Booster tokens to see !
                </Text>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
      {/* Runes claim modal */}
      <ModalDisplay
        width={"25%"}
        open={isClaimModal}
        onCancel={handleCancel}
        footer={null}
        title={
          <Row className="black-bg color-white font-size-23 font-family-one icon-alignment gap-10">
            <LuBaggageClaim color="" /> Claim runes
          </Row>
        }
      >
        <Flex vertical gap={5}>
          <Text className="text-color-two font-size-18 font-family-two">
            Amount
          </Text>
          <Input
            className="inputStyle"
            size="large"
            value={Number(fee) / BTC_ZERO}
            suffix={
              <span className="text-color-one icon-alignment gap-5 font-weight-600 font-family-two">
                <img src={Bitcoin} alt="noimage" width="30dvw" />
                ckBTC
              </span>
            }
            readOnly
          />
        </Flex>
        <Row className="mt-10">
          <Text className="font-family-two color-white font-size-20">
            Wallet balance: {ckBtcValue}
          </Text>
        </Row>

        <Row className="modalBoxYellowShadow mt-10">
          <Col
            className="icon-alignment gap-5"
            style={{ alignItems: "initial" }}
          >
            <HiOutlineInformationCircle className="mt-5" />
            Claimed balance is displayed as mint balance
          </Col>
        </Row>
        <Row>
          <CustomButton
            loading={isTransferLoading}
            className={"font-weight-600 mt-15 width-100 button-style"}
            title={"Transfer & claim runes"}
            onClick={handleCkBtcTransfer}
          />
        </Row>
      </ModalDisplay>{" "}
    </Row>
  );
};
export default PropsContainer(Runes);
