export const mintIdlFactory = ({ IDL }) => {
  const Time = IDL.Int;
  const CanisterDetails = IDL.Record({
    'ordinal_link': IDL.Text,
    'me_link': IDL.Text,
    'ckBTCWallet': IDL.Principal,
    'twitter': IDL.Text,
    'logo': IDL.Text,
    'icp_nft': IDL.Text,
    'enterpot_link': IDL.Text,
    'totalSupply': IDL.Nat,
    'collection_name': IDL.Text,
    'website': IDL.Text,
    'mint_cost': IDL.Nat,
    'canister': IDL.Principal,
    'timestamp': Time,
    'wallet_link': IDL.Text,
    'discord': IDL.Text,
    'bitcoinwallet': IDL.Text,
  });
  const AccountIdentifier__1 = IDL.Text;
  const CkTransactions = IDL.Record({
    'transaction_id': IDL.Text,
    'transactionTime': Time,
    'senderPrincipal': IDL.Principal,
    'canister': IDL.Principal,
    'timestamp': Time,
    'senderAccountID': IDL.Text,
    'coinName': IDL.Text,
    'amount': IDL.Nat,
    'receiver': IDL.Principal,
  });
  const RuneStoneAllocationRequest = IDL.Record({
    'owner': IDL.Text,
    'RuneStones': IDL.Nat,
    'FoundryID': IDL.Nat,
  });
  const TokenIndex = IDL.Nat32;
  const Subaccount = IDL.Vec(IDL.Nat8);
  const Account = IDL.Record({
    'owner': IDL.Principal,
    'subaccount': IDL.Opt(Subaccount),
  });
  const Timestamp = IDL.Nat64;
  const TransferArgs = IDL.Record({
    'to': Account,
    'fee': IDL.Opt(IDL.Nat),
    'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
    'from_subaccount': IDL.Opt(Subaccount),
    'created_at_time': IDL.Opt(Timestamp),
    'amount': IDL.Nat,
  });
  const TransferError = IDL.Variant({
    'GenericError': IDL.Record({
      'message': IDL.Text,
      'error_code': IDL.Nat,
    }),
    'TemporarilyUnavailable': IDL.Null,
    'BadBurn': IDL.Record({ 'min_burn_amount': IDL.Nat }),
    'Duplicate': IDL.Record({ 'duplicate_of': IDL.Nat }),
    'BadFee': IDL.Record({ 'expected_fee': IDL.Nat }),
    'CreatedInFuture': IDL.Record({ 'ledger_time': IDL.Nat64 }),
    'TooOld': IDL.Null,
    'InsufficientFunds': IDL.Record({ 'balance': IDL.Nat }),
  });
  return IDL.Service({
    'acceptCycles': IDL.Func([], [], []),
    'activateRSIC': IDL.Func([IDL.Text], [IDL.Bool], []),
    'addCanister': IDL.Func([CanisterDetails, IDL.Nat], [IDL.Text], []),
    'addClaimRSIC': IDL.Func([IDL.Text, IDL.Nat], [IDL.Bool], []),
    'addCount': IDL.Func(
      [AccountIdentifier__1, IDL.Nat, IDL.Nat],
      [IDL.Bool],
      [],
    ),
    'addPrincipal': IDL.Func([IDL.Principal, IDL.Nat], [IDL.Text], []),
    'addRuneStones': IDL.Func([IDL.Text, IDL.Nat, IDL.Nat], [IDL.Nat], []),
    'addTransactions': IDL.Func(
      [CkTransactions, IDL.Nat],
      [IDL.Principal],
      [],
    ),
    'allocateRuneStones': IDL.Func(
      [IDL.Text, RuneStoneAllocationRequest],
      [IDL.Nat],
      [],
    ),
    'availableCycles': IDL.Func([], [IDL.Nat], ['query']),
    'claimFee': IDL.Func([], [IDL.Nat], ['query']),
    'claimRunes': IDL.Func([AccountIdentifier__1, IDL.Text], [IDL.Bool], []),
    'disableBoostMint': IDL.Func([IDL.Nat], [IDL.Bool], []),
    'enableBoostMint': IDL.Func([IDL.Nat], [IDL.Bool], []),
    'getAccountID': IDL.Func([IDL.Principal], [IDL.Text], ['query']),
    'getActivatedRSIC': IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, Time))],
      ['query'],
    ),
    'getActivatedRSICCount': IDL.Func([], [IDL.Nat], ['query']),
    'getActivatedTime': IDL.Func([IDL.Text], [IDL.Int], ['query']),
    'getBoosterCount': IDL.Func(
      [AccountIdentifier__1],
      [IDL.Opt(IDL.Nat)],
      ['query'],
    ),
    'getBoosterLimitation': IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(AccountIdentifier__1, IDL.Nat))],
      ['query'],
    ),
    'getCanisterDetails': IDL.Func([], [CanisterDetails], ['query']),
    'getClaimBlock': IDL.Func([], [IDL.Nat], ['query']),
    'getClaimedRSIC': IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Nat32, IDL.Nat))],
      ['query'],
    ),
    'getClaimedRSICCount': IDL.Func([], [IDL.Nat], ['query']),
    'getDebug': IDL.Func([], [IDL.Text], ['query']),
    'getMintedICPRuneStone': IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))],
      ['query'],
    ),
    'getPrincipal': IDL.Func([IDL.Nat], [IDL.Vec(IDL.Principal)], ['query']),
    'getRuneBalance': IDL.Func([IDL.Text], [IDL.Nat], ['query']),
    'getRunes': IDL.Func([IDL.Text, IDL.Nat], [IDL.Nat], []),
    'getStartClaimBlock': IDL.Func([], [IDL.Nat], ['query']),
    'getTransactions': IDL.Func(
      [IDL.Principal],
      [IDL.Vec(CkTransactions)],
      ['query'],
    ),
    'getclaimRewards': IDL.Func([], [IDL.Nat], ['query']),
    'miningBooster': IDL.Func([], [IDL.Bool], []),
    'mintBooster': IDL.Func([AccountIdentifier__1], [IDL.Vec(TokenIndex)], []),
    'mintICPRuneStone': IDL.Func(
      [AccountIdentifier__1, IDL.Text],
      [IDL.Vec(TokenIndex)],
      [],
    ),
    'mintPaidBooster': IDL.Func(
      [AccountIdentifier__1],
      [IDL.Vec(TokenIndex)],
      [],
    ),
    'mintRSIC': IDL.Func([AccountIdentifier__1], [IDL.Vec(TokenIndex)], []),
    'mintRuneTokens': IDL.Func(
      [TransferArgs, IDL.Nat],
      [IDL.Variant({ 'Ok': IDL.Nat, 'Err': TransferError })],
      [],
    ),
    'mintRunes': IDL.Func(
      [TransferArgs, IDL.Text],
      [IDL.Variant({ 'Ok': IDL.Nat, 'Err': TransferError })],
      [],
    ),
    'resetBooster': IDL.Func([IDL.Nat], [], []),
    'resetClaimRewards': IDL.Func([IDL.Nat], [IDL.Text], []),
    'resetDebug': IDL.Func([IDL.Nat], [IDL.Nat], []),
    'setActiveCanister': IDL.Func([IDL.Principal, IDL.Nat], [IDL.Text], []),
    'setClaimBlock': IDL.Func([IDL.Nat, IDL.Nat], [IDL.Text], []),
    'setDebugStatus': IDL.Func([IDL.Nat], [IDL.Bool], []),
    'setclaimRewards': IDL.Func([IDL.Nat, IDL.Nat], [IDL.Text], []),
    'startHeartBeat': IDL.Func([IDL.Nat], [IDL.Nat], []),
    'stopHeartBeat': IDL.Func([IDL.Nat], [IDL.Nat], []),
    'wallet_receive': IDL.Func(
      [],
      [IDL.Record({ 'accepted': IDL.Nat64 })],
      [],
    ),
  });
};