import { Col, Layout, Row, Typography } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { FaCopyright } from "react-icons/fa";
import MainContent from "../container/content";
import MainHeader from "../container/header";
import { useEffect, useState } from "react";
import PropsContainer from "../container/props-container";

const MainLayout = (props) => {
  const { Text } = Typography;
  const { reduxState } = props.redux;
  const [scrollPosition, setScrollPosition] = useState(0);
  const activeKey = reduxState.wallet.activeKey;
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Layout>
        <Header
          style={{
            boxShadow:
              scrollPosition > 25 ? "0px 2px 11px 2px rgb(123, 122, 122)" : "",
          }}
          className="header-sticky"
        >
          <Row justify={"center"}>
            <Col xs={23}>
              <MainHeader />
            </Col>
          </Row>
        </Header>
        <Content className="content-style">
          <Row justify={"center"}>
            <Col xs={23}>
              <Row
                style={{
                  animation:
                    activeKey === "3"
                      ? "rune 3s linear infinite"
                      : "now 3s linear infinite",
                }}
                className="snow"
              ></Row>
              <MainContent />
            </Col>
          </Row>
        </Content>
        <Footer style={{ zIndex: 1 }} className="footer-style">
          <Row justify={"center"}>
            <Text className="text-body footer-text font-size-20 font-weight-600 icon-alignment gap-5">
              {" "}
              Rune ICP <FaCopyright /> {new Date().getFullYear()}
            </Text>
          </Row>
        </Footer>
      </Layout>
    </>
  );
};
export default PropsContainer(MainLayout);
