import React from "react";
import { Card } from "antd";
const CardDisplay = ({
  style,
  children,
  className,
  bordered,
  onClick,
  hoverable,
  cover,
}) => (
  <Card
    onClick={onClick}
    className={className}
    hoverable={hoverable}
    cover={cover}
    bordered={bordered}
    style={style}
  >
    {children}
  </Card>
);
export default CardDisplay;
