import { Principal } from "@dfinity/principal";
import {
  Col,
  Divider,
  Flex,
  Row,
  Skeleton,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { FaDiscord, FaSquareXTwitter } from "react-icons/fa6";
import { PiSmileyWinkBold } from "react-icons/pi";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import ThreeDots from "react-loading-icons/dist/esm/components/three-dots";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import icp_fraction_stone from "../../assets/icp_rune_stone.png";
import bitcoin_logo from "../../assets/logo/Bitcoin.png";
import entrepot_logo from "../../assets/logo/entrepot-logo.png";
import magiceden_logo from "../../assets/logo/magiceden.svg";
import { ckBtcIdlFactory } from "../../ckBTC_canister";
import CustomButton from "../../component/button";
import CardDisplay from "../../component/card";
import Loading from "../../component/loading-wrapper/secondary-loader";
import Notify from "../../component/notification";
import TableComponent from "../../component/table";
import PropsContainer from "../../container/props-container";
import { fractionIdlFactory } from "../../fraction_canister";
import { mintIdlFactory } from "../../mint_canister";
import { setIsLoading } from "../../redux/slice/constant";
import {
  DateTimeConverter,
  actorAgentCreator,
  agentCreator,
  boosterCanisterId,
  btcCanisterId,
  fractionCanisterId,
  mintCanisterId,
  sliceAddress,
  tokenIdentifier,
} from "../../utils/common";

const OrdinalsFractions = (props) => {
  /* global BigInt */
  const { Text } = Typography;
  const { reduxState } = props.redux;
  const accountId = reduxState.wallet.accountId;
  const plugAddress = reduxState.wallet.principalId;

  const foundryId = process.env.REACT_APP_FOUNDRY_ID;
  const BTC_ZERO = process.env.REACT_APP_BTC_ZERO;

  const dispatch = useDispatch();

  const [canisterDetails, setCanisterDetails] = useState(null);
  const [adminPrincipals, setAdminPrincipals] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [fractionTokens, setFractionTokens] = useState(null);
  const [mintedDetails, setMintedDetails] = useState(null);
  const [transferLoading, setTransferLoading] = useState(false);
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });

  const getScreenDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setScreenDimensions({ width, height });
  };

  const getAdminPrincipals = async () => {
    const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
    const getPrincipals = await mint_API.getPrincipal(Number(foundryId));
    const principals = getPrincipals.map((principal) =>
      Principal.from(principal).toText()
    );
    setAdminPrincipals(principals);
  };

  const handleCkBtcTransfer = async () => {
    const ckBtcAgent = actorAgentCreator(ckBtcIdlFactory, btcCanisterId);
    const mint_API = agentCreator(mintIdlFactory, mintCanisterId);

    const transferArgs = {
      to: {
        owner: canisterDetails.ckBTCWallet,
        subaccount: [],
      },
      fee: [],
      memo: [],
      created_at_time: [],
      from_subaccount: [],
      amount: BigInt(canisterDetails.mint_cost),
    };
    try {
      setTransferLoading(true);
      const txTimestamp = Date.now();
      const { Ok, Err } = await ckBtcAgent.icrc1_transfer(transferArgs);

      if (Err) {
        Notify("error", "An error occurred in tranferring ckBTC !");
      } else {
        const txId = Number(Ok).toString();

        const txArgs = {
          transaction_id: txId,
          transactionTime: txTimestamp,
          senderPrincipal: Principal.fromText(plugAddress),
          canister: canisterDetails.canister,
          timestamp: Date.now(),
          senderAccountID: accountId,
          coinName: canisterDetails.collection_name,
          amount: canisterDetails.mint_cost,
          receiver: canisterDetails.ckBTCWallet,
        };

        await mint_API.addTransactions(txArgs, Number(foundryId));
        setTransferLoading(false);

        Notify("success", "Purchase successful!");
        await fetchTransactionDetails();
      }
    } catch (error) {
      setTransferLoading(false);
      console.log("ckBtc transfer error", error);
    }
  };

  const fetchCanisterDetails = async () => {
    try {
      const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
      const canisterDetails = await mint_API.getCanisterDetails();
      setCanisterDetails(canisterDetails);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchTransactionDetails = async () => {
    try {
      const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
      const transactionDetails = await mint_API.getTransactions(
        canisterDetails.canister
      );
      setTransactionDetails(transactionDetails);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchMinterFractionDetails = async () => {
    try {
      const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
      const mintedDetails = await mint_API.getMintedICPRuneStone();
      const output = mintedDetails.map((innerArray) => innerArray[0]);
      setMintedDetails(output);
    } catch (error) {
      console.log("fetchMinterFractionDetails error", error);
    }
  };

  const fetchFractionTokens = async () => {
    try {
      const fraction_API = agentCreator(fractionIdlFactory, fractionCanisterId);
      const { ok, err } = await fraction_API.tokens(accountId);

      const resultData = [];
      ok.forEach((data) => {
        const tokenId = tokenIdentifier(fractionCanisterId, data);
        resultData.push(tokenId);
      });

      if (err) {
        setFractionTokens([]);
      } else {
        setFractionTokens(resultData);
      }
    } catch (error) {
      console.log("fetch tokens error", error);
    }
  };
  // console.log(tokenIdentifier("2khqu-ciaaa-aaaam-acksq-cai", 1));
  const handleFractionsMint = async (acId, txIndex) => {
    dispatch(setIsLoading(true));
    // console.log("acId, txIndex", acId, txIndex);
    try {
      const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
      const mintResult = await mint_API.mintICPRuneStone(acId, txIndex);
      if (mintResult) {
        await fetchMinterFractionDetails();
        Notify("success", `Minted ${Number(mintResult)}`);
      } else {
        Notify("error", "Minting failed!");
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      console.log("error", error);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    getAdminPrincipals();
    fetchFractionTokens();
    fetchCanisterDetails();
    fetchMinterFractionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canisterDetails !== null) {
      fetchTransactionDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canisterDetails]);

  useEffect(() => {
    window.addEventListener("resize", getScreenDimensions);
    return () => {
      window.removeEventListener("resize", getScreenDimensions);
    };
  });

  const tableColumns = [
    {
      key: "Index",
      title: "Index",
      align: "center",
      dataIndex: "transaction_id",
      render: (id) => {
        return (
          <Link
            to={`https://dashboard.internetcomputer.org/bitcoin/transaction/${id}`}
            target="_blank"
          >
            <Text className="text-color-two underline pointer links">{id}</Text>
          </Link>
        );
      },
    },
    {
      key: "Amount",
      title: "Amount",
      align: "center",
      dataIndex: "amount",
      render: (amount) => {
        return (
          <Text className="text-color-two">
            {Number(amount) / BTC_ZERO} ckBTC
          </Text>
        );
      },
    },
    {
      key: "Time stamp",
      title: "Date & Time",
      align: "center",
      dataIndex: "timestamp",
      defaultSortOrder: "ascend",
      sorter: (a, b) => Number(a.transactionTime) - Number(b.transactionTime),
      render: (_, obj) => {
        const timeStamp = DateTimeConverter(Number(obj.transactionTime));
        return (
          <Flex vertical align="center" gap={5}>
            <span>{timeStamp[0]}</span>
            <span className="text-color-two">{timeStamp[1]}</span>
          </Flex>
        );
      },
    },
    {
      key: "From",
      title: "From",
      align: "center",
      dataIndex: "senderPrincipal",
      render: (principal) => {
        const address = Principal.from(principal).toText();
        return (
          <Tooltip trigger={"click"} title={"Copied"} color="purple">
            <Text
              className="text-color-two pointer links"
              onClick={() => {
                navigator.clipboard.writeText(address);
              }}
            >
              {sliceAddress(address)}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      key: "To",
      title: "To",
      align: "center",
      dataIndex: "receiver",
      render: (principal) => {
        const address = Principal.from(principal).toText();
        return (
          <Tooltip trigger={"click"} title={"Copied"} color="purple">
            <Text
              className="text-color-two pointer links"
              onClick={() => {
                navigator.clipboard.writeText(address);
              }}
            >
              {sliceAddress(address)}
            </Text>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        {canisterDetails === null ? (
          <Loading
            indicator={
              <ThreeDots
                stroke="#a031fa"
                strokeWidth={"5"}
                fill="#b458ff"
                alignmentBaseline="central"
              />
            }
            spin={!canisterDetails}
          />
        ) : (
          <Row className="card-ordinals">
            <CardDisplay
              className={"float-up-medium"}
              style={{
                backgroundColor: "#78A083",
                width: "100%",
              }}
            >
              <Flex gap={10} justify="space-between">
                <iframe
                  className="border-radius-25 border-black-3"
                  width={150}
                  height={150}
                  title="canister-iframe"
                  src={canisterDetails.ordinal_link}
                />

                <Flex gap={30}>
                  <Flex vertical align="center" gap={25} justify="center">
                    <Text className="font-size-20 font-weight-600 color-black">
                      NFT name
                    </Text>
                    <Text className="font-size-17 color-white">
                      {canisterDetails.collection_name}
                    </Text>
                  </Flex>

                  <Flex vertical align="center" gap={25} justify="center">
                    <Text className="font-size-20 font-weight-600 color-black">
                      Mint cost
                    </Text>
                    <Text className="font-size-17 color-white">
                      {Number(canisterDetails.mint_cost) / Number(BTC_ZERO)}{" "}
                      ckBTC
                    </Text>
                  </Flex>

                  <Flex vertical align="center" gap={25} justify="center">
                    <Text className="font-size-20 font-weight-600 color-black">
                      Available supply
                    </Text>
                    <Text className="font-size-17 color-white">
                      {transactionDetails === null ? (
                        <Loading
                          indicator={
                            <ThreeDots
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!transactionDetails}
                        />
                      ) : (
                        <>
                          {`${
                            Number(canisterDetails.totalSupply) -
                            transactionDetails.length
                          } / 200`}
                        </>
                      )}
                    </Text>
                  </Flex>

                  <Flex vertical align="center" justify="center">
                    <Text className="font-size-20 font-weight-600 color-black">
                      ICP NFT
                    </Text>
                    <img
                      alt="icp_nft"
                      src={canisterDetails.icp_nft}
                      className="border-radius-25"
                      width={50}
                    />{" "}
                    <Tooltip
                      trigger={"hover"}
                      title={Principal.from(canisterDetails.canister).toText()}
                      color="purple"
                    >
                      <Text className="font-size-13 font-weight-600 color-black">
                        {sliceAddress(
                          Principal.from(canisterDetails.canister).toText()
                        )}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Flex>
                <Flex gap={35} align="center">
                  <Link
                    to={canisterDetails.discord}
                    target="_blank"
                    className="color-black"
                  >
                    <FaDiscord size={35} />
                  </Link>
                  <Link
                    to={canisterDetails.twitter}
                    className="color-black"
                    target="_blank"
                  >
                    <FaSquareXTwitter size={30} />
                  </Link>

                  <Link
                    to={canisterDetails.wallet_link}
                    className="color-black"
                    target="_blank"
                  >
                    <img alt="bitcoin" src={bitcoin_logo} width={30} />{" "}
                  </Link>

                  <Link
                    to={canisterDetails.me_link}
                    className="color-black"
                    target="_blank"
                  >
                    <img alt="magiceden" src={magiceden_logo} width={30} />{" "}
                  </Link>

                  <Link
                    to={canisterDetails.me_link}
                    className="color-black"
                    target="_blank"
                  >
                    <img
                      alt="entrepot"
                      className="border-radius-25"
                      src={entrepot_logo}
                      width={30}
                    />{" "}
                  </Link>
                </Flex>

                <Flex align="center">
                  <CustomButton
                    loading={transferLoading}
                    className={"font-weight-600 width-100 button-style"}
                    title={"Mint fractions"}
                    onClick={handleCkBtcTransfer}
                  />
                </Flex>
              </Flex>
              <div className="color-white font-size-20 mt-15">
                Ordinal is secured in the wallet{" "}
                <Link
                  to={canisterDetails.wallet_link}
                  target="_blank"
                  className="color-black underline pointer links"
                >
                  {sliceAddress(canisterDetails.bitcoinwallet)}
                </Link>{" "}
                is fractionalised into 200 ICP NFT. Any further airdrops for the
                particular runestone will be shared among the share holders in
                ICP wallet.
              </div>
            </CardDisplay>
          </Row>
        )}
      </Row>
      {/* Fraction tokens display */}
      <Row justify={"start"} className="mt-30">
        <Text className="color-white font-size-20 font-family-one">
          {fractionTokens === null ? 0 : fractionTokens.length} Ordinals{" "}
          {fractionTokens === null
            ? " Fraction"
            : fractionTokens.length < 0
            ? " Fraction"
            : " Fractions"}{" "}
          - found
        </Text>
      </Row>
      <Row justify={"center"} align={"middle"} className="mt-25 mb-25">
        <Col xs={24}>
          {fractionTokens === null ? (
            <Loading
              indicator={
                <TailSpin
                  stroke="#a031fa"
                  strokeWidth={"5"}
                  fill="#b458ff"
                  alignmentBaseline="central"
                />
              }
              spin={true}
            />
          ) : fractionTokens.length ? (
            <Row gutter={[45, 28]}>
              {fractionTokens.map((token, index) => {
                return (
                  <Col
                    key={`token-${index}`}
                    md={fractionTokens.length === 0 && 6}
                  >
                    <Skeleton loading={fractionTokens.length === 0} active>
                      <CardDisplay
                        bordered={false}
                        style={{
                          backgroundColor: "#78A083",
                          position: "relative",
                          zIndex: 1,
                          padding: "0px !important",
                        }}
                        className={"border-radius-25 float-up-medium"}
                      >
                        <Link
                          to={`https://${fractionCanisterId}.raw.icp0.io/?tokenid=${token}`}
                          target="_blank"
                        >
                          <div className="card">
                            <div className="card-details">
                              <p className="text-body">
                                <img
                                  alt="icp_rune_stone_cards"
                                  // This for Iframe
                                  // src={`https://2khqu-ciaaa-aaaam-acksq-cai.raw.icp0.io/?tokenid=${tokenIdentifier(
                                  //   "2khqu-ciaaa-aaaam-acksq-cai",
                                  //   token
                                  // )}`}
                                  src={icp_fraction_stone}
                                  className="border-radius-25"
                                  width={
                                    screenDimensions.width > 390 ? 300 : 240
                                  }
                                  height={
                                    screenDimensions.width > 390 ? 300 : 240
                                  }
                                />
                              </p>
                            </div>
                          </div>
                        </Link>
                      </CardDisplay>
                    </Skeleton>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Row justify={"center"} className="mt-10">
              <Text className="color-violet-one font-size-20 font-weight-600 icon-alignment gap-5">
                <PiSmileyWinkBold size={25} /> No Ordinals Fraction to see !
              </Text>
            </Row>
          )}
        </Col>
      </Row>
      <Row justify={"center"}>
        <Divider />
      </Row>
      {/* Transactions */}
      <Row justify={"start"}>
        <Text className="color-white font-size-20 font-family-one">
          Transactions
        </Text>
      </Row>
      <Row className="mt-30">
        <Col md={24}>
          <TableComponent
            tableColumns={
              adminPrincipals.includes(plugAddress)
                ? [
                    ...tableColumns,
                    {
                      key: "Action",
                      title: "Action",
                      align: "center",
                      dataIndex: "Action",
                      render: (_, obj) => {
                        return (
                          <>
                            {!mintedDetails.includes(obj.transaction_id) ? (
                              <CustomButton
                                loading={!mintedDetails}
                                className={
                                  "font-weight-600 width-100 button-style"
                                }
                                title={"Mint"}
                                onClick={() => {
                                  handleFractionsMint(
                                    obj.senderAccountID,
                                    obj.transaction_id
                                  );
                                }}
                              />
                            ) : (
                              <Tag color="green">Minted</Tag>
                            )}
                          </>
                        );
                      },
                    },
                  ]
                : tableColumns
            }
            tableData={transactionDetails}
          />
        </Col>
      </Row>{" "}
    </>
  );
};

export default PropsContainer(OrdinalsFractions);
