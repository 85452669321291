import { Col, Grid, Row, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { PiCopyBold } from "react-icons/pi";
import logo from "../../assets/logo/logo.png";
import CustomButton from "../../component/button";
import Notify from "../../component/notification";
// import { clearWallet, setAccountId, setPrincipalId } from "../../redux/slice";
import { sliceAddress } from "../../utils/common";
import PropsContainer from "../props-container";
import { IoLogOutOutline } from "react-icons/io5";
import { TbPlugConnected } from "react-icons/tb";
import {
  clearWallet,
  setAccountId,
  setPrincipalId,
} from "../../redux/slice/wallet";

const MainHeader = (props) => {
  const { Text } = Typography;
  const { useBreakpoint } = Grid;
  const breakPoint = useBreakpoint();

  const { dispatch, reduxState } = props.redux;
  const [copy, setCopy] = useState("copy");
  const plugAddress = reduxState.wallet.principalId;

  const connectWallet = async () => {
    if (window.ic.plug) {
      const whitelist = [
        process.env.REACT_APP_MINT_CANISTER_ID,
        process.env.REACT_APP_NFT_CANISTER_ID,
        process.env.REACT_APP_BTC_CANISTER_ID,
        process.env.REACT_APP_TOKEN_CANISTER_ID,
        process.env.REACT_APP_BOOSTER_CANISTER_ID,
      ];
      const host = process.env.REACT_APP_AGENT_HOST;
      const onConnectionUpdate = () => {
        const { accountId, principalId } =
          window.ic.plug.sessionManager.sessionData;
        dispatch(setAccountId(accountId));
        dispatch(setPrincipalId(principalId));
      };
      try {
        await window.ic.plug.requestConnect({
          whitelist,
          host,
          onConnectionUpdate,
          timeout: 50000,
        });
        dispatch(setAccountId(window.ic.plug.accountId));
        dispatch(setPrincipalId(window.ic.plug.principalId));
      } catch (error) {
        // Notify("error", error.message);
        console.log("error", error);
      }
    } else {
      Notify("error", "Install plug wallet!");
    }
  };

  const signoutWallet = () => {
    dispatch(clearWallet());
  };

  return (
    <Row justify={"space-between"} align={"middle"}>
      <Col>
        <Row align={"middle"}>
          <img src={logo} alt="logo" width={80} className="m-10 pointer" />
          {!breakPoint.xs && (
            <Text className="font-size-25 typewriter font-color-one font-family-one pointer transition-p-5 font-weight-600 font-logo">
              Rune ICP
            </Text>
          )}
        </Row>
      </Col>
      {plugAddress && (
        <Col>
          <Text className="color-white font-size-18 icon-alignment gap-5">
            {sliceAddress(plugAddress, 4)}
            <Tooltip
              color="#7a09d7"
              placement="right"
              title={copy}
              className="pointer"
              onClick={() => {
                navigator.clipboard.writeText(plugAddress);
                setCopy("copied");
                setTimeout(() => {
                  setCopy("copy");
                }, 3000);
              }}
            >
              <PiCopyBold />
            </Tooltip>
          </Text>
        </Col>
      )}

      {!breakPoint.xs ? (
        <Col>
          <CustomButton
            className={"button-style font-weight-600 letter-spacing-1"}
            title={plugAddress ? "Sign out" : "Connect"}
            onClick={plugAddress ? signoutWallet : connectWallet}
          />
        </Col>
      ) : (
        <Col>
          {plugAddress ? (
            <IoLogOutOutline
              className="mt-20"
              onClick={signoutWallet}
              color="white"
              size={40}
            />
          ) : (
            <TbPlugConnected
              className="mt-20"
              onClick={connectWallet}
              color="white"
              size={40}
            />
          )}
        </Col>
      )}
    </Row>
  );
};
export default PropsContainer(MainHeader);
