import { Col, Flex, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { BiSolidUpArrowSquare } from "react-icons/bi";
import { MdRocketLaunch } from "react-icons/md";
import ThreeDots from "react-loading-icons/dist/esm/components/three-dots";
import { boosterIdlFactory } from "../../booster_canister";
import CardDisplay from "../../component/card";
import Loading from "../../component/loading-wrapper/secondary-loader";
import { mintIdlFactory } from "../../mint_canister";
import {
  agentCreator,
  boosterCanisterId,
  mintCanisterId,
} from "../../utils/common";

const Statistics = () => {
  const { Text } = Typography;
  const [stats, setStats] = useState({
    activatedRSIC: null,
    boosterSupplies: null,
  });

  const fetchCounts = async () => {
    try {
      const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
      const booster_API = agentCreator(boosterIdlFactory, boosterCanisterId);

      const RSICs = await mint_API.getActivatedRSICCount();

      const { ok } = await booster_API.supply("RSIC");
      setStats({
        activatedRSIC: Number(RSICs),
        boosterSupplies: Number(ok),
      });
    } catch (error) {
      console.log("Stats error", error);
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);
  return (
    <Row justify={"center"}>
      <Col md={24}>
        <Row className="mt-10" justify={"start"} gutter={80}>
          <Col>
            <CardDisplay style={{ width: "200px" }} className={"card-bg"}>
              <Row
                justify={"space-between"}
                align={"middle"}
                className="gap-5 icon-alignment"
              >
                <Flex vertical>
                  <Text className="font-size-18">Activated RSICs</Text>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      {stats.activatedRSIC !== null ? (
                        <Text className="font-size-18 font-weight-600">
                          {stats.activatedRSIC}
                        </Text>
                      ) : (
                        <Loading
                          indicator={
                            <ThreeDots
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!stats.activatedRSIC}
                        />
                      )}
                    </Col>
                    <Col>
                      <Text>
                        <BiSolidUpArrowSquare
                          color="green"
                          className="launchIconStyle-one scale-animation mt-5 pointer"
                          size={30}
                        />
                      </Text>
                    </Col>
                  </Row>
                </Flex>
              </Row>
            </CardDisplay>
          </Col>
          <Col>
            <CardDisplay style={{ width: "200px" }} className={"card-bg"}>
              <Row
                justify={"space-between"}
                align={"middle"}
                className="gap-5 icon-alignment"
              >
                <Flex vertical>
                  <Text className="font-size-18">Booster supply</Text>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      {stats.boosterSupplies !== null ? (
                        <Text className="font-size-18 font-weight-600">
                          {stats.boosterSupplies}
                        </Text>
                      ) : (
                        <Loading
                          indicator={
                            <ThreeDots
                              stroke="#a031fa"
                              strokeWidth={"5"}
                              fill="#b458ff"
                              alignmentBaseline="central"
                            />
                          }
                          spin={!stats.boosterSupplies}
                        />
                      )}
                    </Col>
                    <Col>
                      <Text>
                        <MdRocketLaunch
                          color="red"
                          className="launchIconStyle scale-animation mt-5 pointer"
                          size={30}
                        />
                      </Text>
                    </Col>
                  </Row>
                </Flex>
              </Row>
            </CardDisplay>
          </Col>
        </Row>
      </Col>
      <div style={{ height: "400px" }}></div>z
    </Row>
  );
};

export default Statistics;
