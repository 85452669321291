import React from "react";
import { useDispatch, useSelector } from "react-redux";

const PropsContainer = (Component) => {
  function ComponentWithRouterProp(props) {
    const dispatch = useDispatch();
    const reduxState = useSelector((state) => state);

    return <Component {...props} redux={{ dispatch, reduxState }} />;
  }
  return ComponentWithRouterProp;
};
export default PropsContainer;
