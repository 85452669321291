import { Badge, Row, Tabs, Typography } from "antd";
import React, { useEffect } from "react";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import CustomButton from "../../component/button";
// import { setActiveKey } from "../../redux/slice";
import { setActiveKey } from "../../redux/slice/wallet";
import Mint from "../../tab_pages/mint";
import Nft from "../../tab_pages/nft";
import OrdinalsFraction from "../../tab_pages/ordinals_fractions";
import Runes from "../../tab_pages/runes";
import Statistics from "../../tab_pages/statistics";
import PropsContainer from "../props-container";

const MainContent = (props) => {
  const { reduxState, dispatch } = props.redux;
  const { Text } = Typography;
  const activeKey = reduxState.wallet.activeKey;
  const plugAddress = reduxState.wallet.principalId;

  const onChange = (key) => {
    if (key !== "6") dispatch(setActiveKey(key));
  };

  useEffect(() => {
    if (!plugAddress) {
      dispatch(setActiveKey("1"));
    }
  }, [plugAddress, dispatch]);

  const items = [
    {
      key: "1",
      label: "Mint",
      children: <Mint />,
    },
    {
      key: "2",
      label: "NFT",
      children: <Nft activeKey={activeKey} />,
    },
    {
      key: "3",
      label: (
        <Badge
          count={"New"}
          style={{
            backgroundColor: "#52c41a",
            boxShadow: "none",
            color: "black",
          }}
        >
          <Text
            className={`${
              activeKey === "1" ? "ant-tabs-tab-active" : ""
            } ant-tabs-tab-btn ant-tabs-tab`}
          >
            Ordinals Fractions
          </Text>
        </Badge>
      ),
      children: <OrdinalsFraction activeKey={activeKey} />,
    },
    {
      key: "4",
      label: (
        <Badge
          count={"New"}
          style={{
            backgroundColor: "#52c41a",
            boxShadow: "none",
            color: "black",
          }}
        >
          <Text
            className={`${
              activeKey === "1" ? "ant-tabs-tab-active" : ""
            } ant-tabs-tab-btn ant-tabs-tab`}
          >
            Claim Runes
          </Text>
        </Badge>
      ),
      children: <Runes activeKey={activeKey} />,
    },
    {
      key: "5",
      label: "Statistics",
      children: <Statistics activeKey={activeKey} />,
    },
    {
      key: "6",
      label: (
        <Link
          to="https://twitter.com/rune_icp"
          target="_blank"
          className="font-color-two font-size-17 font-family-two"
        >
          <CustomButton className={"twitter-button"} title={<FaXTwitter />} />
        </Link>
      ),
    },
  ];

  return (
    <>
      {plugAddress ? (
        <>
          <Tabs
            centered
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            activeKey={activeKey}
          />
        </>
      ) : (
        <>
          <Row className={`mt-30 ant-tabs-content-holder`}>
            <Mint />
          </Row>
        </>
      )}
    </>
  );
};
export default PropsContainer(MainContent);
