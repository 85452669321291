import { createSlice } from "@reduxjs/toolkit";

const state = {
  activeKey: "1",
  accountId: "",
  principalId: "",
  booster: {
    tokens: [],
    isBoosted: false,
  },
  ckBtcValue: 0
};

const walletSlice = createSlice({
  name: "wallet",
  initialState: state,
  reducers: {
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setPrincipalId: (state, action) => {
      state.principalId = action.payload;
    },
    setBoosterTokens: (state, action) => {
      state.booster.tokens = action.payload;
      state.booster.isBoosted = action.payload.length ? true : false;
    },
    setCkBtcValue: (state, action) => {
      state.ckBtcValue = action.payload;
    },
    clearWallet: (state, action) => {
      state.principalId = "";
      state.accountId = "";
    },
  },
});

export const {
  setActiveKey,
  setPrincipalId,
  setCkBtcValue,
  setAccountId,
  setBoosterTokens,
  clearWallet,
} = walletSlice.actions;
export default walletSlice.reducer;
