import { createSlice } from "@reduxjs/toolkit";

const state = {
  isLoading: false,
  mintAgent: null,
  nftAgent: null,
  ckBtcActorAgent: null
};

const constantSlice = createSlice({
  name: "constant",
  initialState: state,
  reducers: {
    setMintAgent: (state, action) => {
      state.mintAgent = action.payload;
    },
    setNftAgent: (state, action) => {
      state.nftAgent = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCkBtcActorAgent: (state, action) => {
      state.ckBtcActorAgent = action.payload;
    },
  },
});

export const {
  setMintAgent,
  setNftAgent,
  setCkBtcActorAgent,
  setIsLoading
} = constantSlice.actions;
export default constantSlice.reducer;