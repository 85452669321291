import { Principal } from "@dfinity/principal";
import { initJuno } from "@junobuild/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { ckBtcIdlFactory } from "./ckBTC_canister";
import LoadingWrapper from "./component/loading-wrapper";
import MainLayout from "./layout";
import { setCkBtcValue } from "./redux/slice/wallet";
import { agentCreator, btcCanisterId } from "./utils/common";

const App = () => {
  const BTC_ZERO = process.env.REACT_APP_BTC_ZERO;

  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const plugAddress = reduxState.wallet.principalId;

  const fetchBtcAssetBalance = async () => {
    const ckBtcActorAgent = agentCreator(ckBtcIdlFactory, btcCanisterId);

    let ckBtcBalance = await ckBtcActorAgent.icrc1_balance_of({
      owner: Principal.fromText(plugAddress),
      subaccount: [],
    });

    if (Number(ckBtcBalance) < 99) {
      ckBtcBalance = 0;
    }
    dispatch(setCkBtcValue(Number(ckBtcBalance) / BTC_ZERO));
  };

  useEffect(() => {
    if (plugAddress) {
      fetchBtcAssetBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plugAddress]);

  useEffect(() => {
    (async () =>
      await initJuno({
        satelliteId: "dawzl-xyaaa-aaaal-advoa-cai",
      }))();
  }, []);

  return (
    <BrowserRouter>
      <LoadingWrapper>
        <MainLayout />
      </LoadingWrapper>
    </BrowserRouter>
  );
};
export default App;
