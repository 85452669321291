import { Principal } from "@dfinity/principal";
import { Badge, Col, Flex, Input, Row, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaCartPlus } from "react-icons/fa6";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { TailSpin } from "react-loading-icons";
import { Link } from "react-router-dom";
import Bitcoin from "../../assets/ckbtc.png";
import logo from "../../assets/logo/rune-icp-grey-color.png";
import { boosterIdlFactory } from "../../booster_canister";
import { ckBtcIdlFactory } from "../../ckBTC_canister";
import CustomButton from "../../component/button";
import Loading from "../../component/loading-wrapper/secondary-loader";
import ModalDisplay from "../../component/modal";
import Notify from "../../component/notification";
import PropsContainer from "../../container/props-container";
import { mintIdlFactory } from "../../mint_canister";
import { nftIdlFactory } from "../../nft_canister";
import { setIsLoading } from "../../redux/slice";
import {
  actorAgentCreator,
  agentCreator,
  boosterCanisterId,
  btcCanisterId,
  mintCanisterId,
  nftCanisterId,
  runeIcpCustody,
} from "../../utils/common";

const Mint = (props) => {
  /* global BigInt */
  const { Text } = Typography;
  const { reduxState, dispatch } = props.redux;
  const accountId = reduxState.wallet.accountId;
  const plugAddress = reduxState.wallet.principalId;
  const ckBtcValue = reduxState.wallet.ckBtcValue;

  const BTC_ZERO = process.env.REACT_APP_BTC_ZERO;

  const [screenDimensions, setScreenDimensions] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });
  const [blocks, setBlocks] = useState(0);
  const [nftTokens, setNftTokens] = useState([]);
  const [mintModal, setMintModal] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [boostedSupply, setBoostedSupply] = useState(0);

  const getScreenDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setScreenDimensions({ width, height });
  };

  const handleMintPaidBooster = async () => {
    try {
      dispatch(setIsLoading(true));
      if (nftTokens.length > 0) {
        const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
        const result = await mint_API.mintPaidBooster(accountId);

        if (result.length) {
          Notify("success", "Mints Boosted successfully !");
          fetchBoostedSupply();
        } else {
          Notify("error", "An error occurred !");
        }
      } else {
        Notify("warning", "Acquire a RSIC from entrepot !");
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.log("error", error.message);
    }
  };

  const handleMintBooster = async () => {
    try {
      dispatch(setIsLoading(true));
      if (nftTokens.length > 0) {
        const mint_API = agentCreator(mintIdlFactory, mintCanisterId);
        const result = await mint_API.mintBooster(accountId);

        if (result.length) {
          Notify("success", "Mints Boosted successfully !");
          fetchBoostedSupply();
        } else {
          Notify("error", "An error occurred !");
        }
      } else {
        Notify("warning", "Acquire a RSIC from entrepot !");
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.log("error", error.message);
    }
  };

  const handleCancel = () => {
    setMintModal(false);
  };

  const handleCkBtcTransfer = async () => {
    const ckBtcAgent = actorAgentCreator(ckBtcIdlFactory, btcCanisterId);
    const booster_API = agentCreator(boosterIdlFactory, boosterCanisterId);

    const transferArgs = {
      to: {
        owner: Principal.fromText(runeIcpCustody),
        subaccount: [],
      },
      fee: [],
      memo: [],
      created_at_time: [],
      from_subaccount: [],
      amount: BigInt(0.00006666 * Number(BTC_ZERO)),
      // amount: 1n,
    };
    try {
      setTransferLoading(true);
      const { Ok, Err } = await ckBtcAgent.icrc1_transfer(transferArgs);

      if (Err) {
        Notify("error", "An error occurred in tranferring ckBTC !");
      } else {
        const txId = Number(Ok).toString();

        const txArgs = {
          principal: Principal.fromText(plugAddress),
          accountid: accountId,
          transaction: txId,
          timestamp: Date.now(),
        };

        const txResult = await booster_API.addTransactions(5871, txArgs);

        setMintModal(false);
        setTransferLoading(false);

        if (txResult === txId) {
          await handleMintPaidBooster();
        } else {
          Notify("error", "An error occurred in storing transaction data !");
        }
      }
    } catch (error) {
      setTransferLoading(false);
      console.log("ckBtc transfer error", error);
    }
  };

  const fetchBoostedSupply = async () => {
    const booster_API = agentCreator(boosterIdlFactory, boosterCanisterId);
    const { ok, err } = await booster_API.supply("RSIC");

    if (err) {
    } else {
      setBoostedSupply(Number(ok));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getScreenDimensions);
    return () => {
      window.removeEventListener("resize", getScreenDimensions);
    };
  });

  useEffect(() => {
    (async () => {
      if (accountId) {
        const nft_API = agentCreator(nftIdlFactory, nftCanisterId);
        const { ok, err } = await nft_API.tokens(accountId);
        if (err) {
        } else {
          setNftTokens(ok);
        }
      }
    })();
  });

  useEffect(() => {
    (async () => {
      if (accountId) {
        await fetchBoostedSupply();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        "https://mempool.space/api/blocks/tip/height"
      );
      setBlocks(data);
    })();
  }, []);

  return (
    <Row justify={"center"}>
      <Col lg={20}>
        {/* <Row className="snow"></Row> */}
        <Row justify={"center"}>
          <Text
            style={{ color: "#f5edce" }}
            className="color-white font-size-30 underline animate-charcter"
          >
            Rune Specific Internet Canisters{" "}
            {screenDimensions.width > 840 && "(RSIC)"}
          </Text>
        </Row>
        <Row justify={"center"} className="mt-20">
          <Text className="font-size-20 font-family-two font-color-two">
            RSIC: A PEER-TO-PEER RUNE ALLOCATION SYSTEM
          </Text>
        </Row>
        <Row className="mt-25">
          <Text className="color-white font-size-20 font-family-two">
            This is the first rune on Internet Computer Protocol.
          </Text>
        </Row>
        <Row className="mt-25">
          <Text className="color-white font-size-20 font-family-two">
            Read the rules of the game below if you choose to play.
          </Text>
        </Row>
        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            SUPPLY CHAIN:
          </Text>
        </Row>
        <Row className="mt-15">
          <Col xl={18} md={16} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              RSIC Distribution Centers
            </Text>
          </Col>
          <Col xl={6} md={8} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              - RSIC availability
            </Text>
          </Col>
        </Row>
        <Row>
          <Col xl={18} md={16} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              RSIC Controller
            </Text>
          </Col>
          <Col xl={6} md={8} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              - RSIC & rune distribution
            </Text>
          </Col>
        </Row>
        <Row>
          <Col xl={18} md={16} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              RSIC Factory
              {screenDimensions.width > 767 &&
                "(Rune Specific Internet Canisters)"}
            </Text>
          </Col>
          <Col xl={6} md={8} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              - Purpose: mine runes.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col xl={18} md={16} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              RSIC Booster Factory
            </Text>
          </Col>
          <Col xl={6} md={8} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              - Boost miners
            </Text>
          </Col>
        </Row>
        <Row>
          <Col xl={18} md={16} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              Rune Foundry.
              {screenDimensions.width > 767 && "Purpose: generate runes."}
            </Text>
          </Col>
          <Col xl={6} md={8} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              - Rune Token Canister
            </Text>
          </Col>
        </Row>

        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            SITUATION:
          </Text>
        </Row>
        <Row>
          <Text className="font-color-two font-size-20 font-family-two mt-20">
            A technological arms race has begun. 6,009 cutting-edge RSICs,
            manufactured in our Factory, are being availed from our Distribution
            Centers to the ICP community as a Free mint. The RSICs are designed
            for the sole purpose of securing a bag of runes. These runes will be
            generated, in our Foundry, after the runes protocol launches on
            bitcoin or at the 840,000 Block.
          </Text>
        </Row>

        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            RULES OF THE GAME:
          </Text>
        </Row>
        <Row className="font-color-two font-family-two mt-15 font-size-20">
          1. All RSICs are NFTs. 6,009 were manufactured in total. Our factory
          and distribution centers have since mysteriously exploded.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          2. All RSICs are being availed to the ICP community as Free mint.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          3. RSIC holders have 3 choices: (1) mine runes. (2) sell RSICs on
          market. (3) fade.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          4. RSICs must be activated before they start mining. To activate your
          RSIC in the Rune Distribution Centers decentralised application.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          5. Every bitcoin block, runes will be allocated to the address holding
          activated RSIC(s). Until the supply is fully allocated.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          6. Rune allocations stack (more RSICs = more runes). There is no limit
          on the number of RSICs per address.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          7. There are 4 types of rune allocation: flat, random, boosted, and
          p-halving.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          8. RSICs holders till token generation event will be rewarded with
          runes.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          9. 7% of RSICs are reserved for the game designers.
        </Row>
        <Row className="font-color-two font-family-two  font-size-20">
          10.3% of RSICs are reserved for the game promoters & influencers on
          FCFS basis.
        </Row>

        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            RUNE ALLOCATION TYPES & DISTRIBUTION:
          </Text>
        </Row>
        <Row className="font-color-two font-family-two mt-25 font-size-20">
          1. FLAT (up to 30% of fully diluted rune supply): a flat reward of 6
          runes per bitcoin block per active RSIC.
        </Row>
        <Row className="font-color-two font-family-two mt-25 font-size-20">
          2. BOOSTED (up to 30% of fully diluted rune supply): a single boost
          will be available to all RSICs. The instructions will be published
          after 24 hours of all RSICs minted.
        </Row>
        <Row className="font-color-two font-family-two mt-25 font-size-20">
          3. RANDOM (25% baseline + residual runes from flat and boosted): an
          additional reward per block allocated via random hash alignment. If
          your RSICs type code aligns with the last digit of a given blockhash,
          your RSIC mines 336 runes in that block. All RSICs indicate hash
          alignment with current block with a visual cue (hint: orange).
        </Row>
        <Row className="font-color-two font-family-two mt-25 font-size-20">
          4. POST-HALVING: 5 allocations (5% + 4% + 3% + 2% + 1% of fully
          diluted rune supply) will be made on block 840,000. Each RSIC gets one
          ticket for this raffle for every consecutive block it was held in the
          address holding it when block 840,000 is mined.
        </Row>
        <Row className="color-white font-family-two mt-25 ">
          <span className=" font-size-20 animate-charcter">
            SOURCES OF TRUTH
          </span>
          <span className="ml-5 font-size-20 font-color-two font-family-two">
            (from most to least authoritative):
          </span>
        </Row>

        <Row>
          <Text className=" font-color-two mt-20 font-size-20 font-family-two">
            1.{" "}
            <Link
              to={"https://twitter.com/rune_icp"}
              target="_blank"
              className=" font-color-two mt-20 font-size-20 font-family-two"
            >
              @rune_icp{" "}
            </Link>
            on Twitter (AKA x.com).
          </Text>
        </Row>
        <Row>
          <Text className=" font-color-two font-size-20 font-family-two">
            2.{" "}
            <Link
              to="https://dawzl-xyaaa-aaaal-advoa-cai.icp0.io"
              target="_blank"
              className=" font-color-two font-size-20 font-family-two"
            >
              https://dawzl-xyaaa-aaaal-advoa-cai.icp0.io
            </Link>
            .
          </Text>
        </Row>
        <Row>
          <Text className=" font-color-two  font-size-20 font-family-two">
            3.{" "}
            <Link
              to={"https://bit.ly/rune-icp-winners-form"}
              target="_blank"
              className="color-white font-size-20 font-family-two"
            >
              https://bit.ly/rune-icp-winners-form
            </Link>
            .
          </Text>
        </Row>

        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            CANISTERS
          </Text>
        </Row>
        <Row className="mt-25">
          <Col md={10} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              4. RSIC Distribution Centers.
            </Text>
          </Col>
          <Col md={14} xs={12}>
            <Link
              to={"https://dawzl-xyaaa-aaaal-advoa-cai.icp0.io"}
              target="_blank"
              className="font-size-20 font-family-two"
            >
              https://dawzl-xyaaa-aaaal-advoa-cai.icp0.io
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={10} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              5. RSIC Controller
            </Text>
          </Col>
          <Col md={14} xs={12}>
            {" "}
            <Text className="font-color-two font-size-20 font-family-two">
              emcel-ryaaa-aaaam-ab7iq-cai
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={10} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              6. RSIC Factory
            </Text>
          </Col>
          <Col md={14} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              efbpx-hqaaa-aaaam-ab7ja-cai
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={10} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              7. RSIC Booster Factory
            </Text>
          </Col>
          <Col md={14} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              fbmaf-6qaaa-aaaam-ab7pa-cai
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={10} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              8. Rune Foundry
            </Text>
          </Col>
          <Col md={14} xs={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              7mokm-cqaaa-aaaam-acb6q-cai
            </Text>
          </Col>
        </Row>
        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            PROMOTERS
          </Text>
        </Row>
        <Row>
          <Text className="font-color-two font-size-20 font-family-two">
            Promoters can promote the game on any social media. Distribution of
            RSIC shall be FCFS basis Tag @rune_icp add your content.
          </Text>
        </Row>
        <Row className="mt-20">
          <Text className="font-color-two font-size-20 font-family-two">
            Reward claim form https://bit.ly/rune-icp-winners-form.
          </Text>
        </Row>
        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            CLARITY
          </Text>
        </Row>
        <Row className="mt-20">
          <Text className=" font-color-two font-size-20 font-family-two">
            The Jupiter dex airdropped 6.7 billion dollars to the solana
            community last month. Now it's ICP's turn.
          </Text>
        </Row>
        <Row className="mt-20">
          <Text className=" font-color-two font-size-20 font-family-two">
            Rune is a meme coin with no utility and a free mint for the most
            loyal icp community in crypto.
          </Text>
        </Row>
        <Row className="mt-25">
          <Text className="color-white font-size-20 animate-charcter">
            DISCLAIMER:
          </Text>
        </Row>
        <Row className="mt-20">
          <Text className="font-color-two font-size-20 font-family-two">
            The Rune is a fun experiment without any guarantee or any benefits
            to the minter or holders. Always DYOR & AYOR.
          </Text>
        </Row>
        <Row className="mt-20">
          <Col md={12}>
            <Text className="font-color-two font-size-20 font-family-two">
              "Each player must accept the cards life deals him or her: but once
              they are in hand, he or she alone must decide how to play the
              cards in order to win the game." -- Voltaire
            </Text>
          </Col>
        </Row>
        <Row className="mt-20" justify={"space-between"}>
          <Col>
            <Text className="color-white font-size-20 font-family-two">
              Good luck players !.
            </Text>
          </Col>
        </Row>
        {/* ------------------------------ */}

        <Row justify={"center"} className="mt-25" gutter={32}>
          {accountId ? (
            <>
              {/* <Col md={5}>
                <CustomButton
                  loading={!mintSupply}
                  style={{ width: "100%" }}
                  className={
                    "activate-button color-black button-style font-weight-600 icon-float-3s letter-spacing-1"
                  }
                  title={`Mint ${6009 - mintSupply} / 6009`}
                  onClick={handleMint}
                />
              </Col> */}

              <Col md={5}>
                <Badge
                  count={"fee"}
                  className="icon-float-4s"
                  style={{
                    boxShadow: "none",
                    color: "white",
                  }}
                  color={"volcano"}
                >
                  <CustomButton
                    style={{ width: "100%" }}
                    className={
                      "activate-button color-black button-style font-weight-600 letter-spacing-1"
                    }
                    title={
                      <Row justify={"center"}>
                        <Text>{`Mint Booster`}</Text>
                        <Row className="shine"></Row>
                      </Row>
                    }
                    onClick={() => {
                      if (nftTokens.length < 1) {
                        Notify(
                          "warning",
                          "Buy one from https://entrepot.app/marketplace/rsic"
                        );
                      } else {
                        boostedSupply <= 99
                          ? handleMintBooster()
                          : setMintModal(true);
                      }
                    }}
                  />
                </Badge>
              </Col>
            </>
          ) : (
            <Row justify={"center"} align={"middle"}>
              <Col>
                <Row justify={"center"}>
                  <img
                    src={logo}
                    alt="logo"
                    width={150}
                    className="m-10 pointer icon-float-3s border-radius-50"
                  />
                </Row>
                <Row justify={"center"} className="mt-10">
                  <Text className="font-size-20 color-white letter-spacing-1 font-weight-600">
                    Please connect your wallet to Mint !
                  </Text>
                </Row>
              </Col>
            </Row>
          )}
        </Row>

        {boostedSupply && nftTokens.length === 0 && blocks > 833700 && (
          <Row justify={"center"} className="mt-30">
            <Link
              to={"https://entrepot.app/marketplace/rsic"}
              style={{
                color: "lightblue",
                textDecoration: "underline",
              }}
              className="font-family-two font-size-20"
              target="_blank"
            >
              You don't have any RSIC miner to get boosters, Click here to
              acquire one!
            </Link>
          </Row>
        )}

        {blocks < 833700 && (
          <div className="booster-container mt-30">
            <span className="tag">Boosters</span>

            <Row justify={"center"}>
              <Text
                style={{
                  color: "lightblue",
                }}
                className="font-family-two font-size-23"
              >
                RSIC boosters are arriving shortly and will be available once
                blocks reached 833700.
              </Text>
            </Row>

            <Row justify={"center"} className="mt-15">
              {blocks ? (
                <Text className="font-size-18 font-color-one">
                  Current block height {blocks} !
                </Text>
              ) : (
                <Loading indicator={<TailSpin />} spin={!blocks} />
              )}
            </Row>
          </div>
        )}
      </Col>

      <ModalDisplay
        width={"25%"}
        open={mintModal}
        onCancel={handleCancel}
        footer={null}
        title={
          <Row className="black-bg color-white font-size-23 font-family-one icon-alignment gap-10">
            <FaCartPlus color="" /> Acquire Boosters
          </Row>
        }
      >
        <Flex vertical gap={5}>
          <Text className="text-color-two font-size-18 font-family-two">
            Amount
          </Text>
          <Input
            className="inputStyle"
            size="large"
            value={0.00006666}
            suffix={
              <span className="text-color-one icon-alignment gap-5 font-weight-600 font-family-two">
                <img src={Bitcoin} alt="noimage" width="30dvw" />
                ckBTC
              </span>
            }
            readOnly
          />
        </Flex>
        <Row className="mt-10">
          <Text className="font-family-two color-white font-size-20">
            Wallet balance: {ckBtcValue}
          </Text>
        </Row>

        <Row className="modalBoxYellowShadow mt-10">
          <Col
            className="icon-alignment gap-5"
            style={{ alignItems: "initial" }}
          >
            <HiOutlineInformationCircle className="mt-5" />
            Boosters increases mining by 30%.
          </Col>
        </Row>
        <Row>
          <CustomButton
            loading={transferLoading}
            className={"font-weight-600 mt-15 width-100 button transfer-button"}
            title={"Boost miner"}
            onClick={handleCkBtcTransfer}
          />
        </Row>
      </ModalDisplay>
    </Row>
  );
};

export default PropsContainer(Mint);
